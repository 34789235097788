<template>
  <div id="app">
    <div class="topBar" :class="{'active':this.menuOpen}">
      <router-link class="logo mobile" :to="{name: 'home'}" v-show="!this.menuOpen"></router-link>
      <div class="switcher" @click="toggleNav" :class="{'active':this.menuOpen}">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
    </div>
    <div
      class="mobileNav"
      @click="toggleNav"
      style="display: none;"
      :class="{'active':this.menuOpen}"
    >
      <div class="lang" style="display:none">ZH / EN</div>
      <!--<a target="_blank" href="http://www.tsaiyitech.com/activity/FansPlay_BoostYourPlan/tw/">
        News
        <br>
        <span>最新消息</span>
      </a>-->
      <router-link :to="{name:'about'}" :class="{'active':this.currentPage=='about'}">ABOUT
        <br>
        <span>關於我們</span>
      </router-link>
      <router-link :to="{name:'service'}" :class="{'active':this.currentPage=='service'}">SERVICE
        <br>
        <span>服務項目</span>
      </router-link>
      <router-link :to="{name:'products'}" :class="{'active':this.currentPage=='products'}">PRODUCT
        <br>
        <span>產品</span>
      </router-link>
      <router-link
        :to="{name:'CaseStudies'}"
        :class="{'active':this.currentPage=='CaseStudies'}"
      >CASES
        <br>
        <span>精選案例</span>
      </router-link>
      <router-link :to="{name:'blog'}" :class="{'active':this.currentPage=='blog'}">BLOG
        <br>
        <span>部落格</span>
      </router-link>
      <router-link :to="{name:'contact'}" :class="{'active':this.currentPage=='contact'}">CONTACT
        <br>
        <span>聯絡我們</span>
      </router-link>
      <hr v-show="this.currentPage!='home'">
    </div>
    <div id="nav" :class="{'inPage':this.currentPage!='home','hooked': this.hooked}">
      <router-link class="logo" :to="{name: 'home'}" :class="{'small':this.currentPage!='home'}"></router-link>
      <nav>
        <div class="lang">ZH / EN</div>

        <!--<a target="_blank" href="http://www.tsaiyitech.com/activity/FansPlay_BoostYourPlan/tw/">
          News
          <br>
          <span>最新消息</span>
        </a>-->

        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'about'}"
          :class="{'active':this.currentPage=='about' || (this.currentSection == 1 && this.currentPage == 'home')}"
        >About
          <br>關於我們
        </router-link>
        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'service'}"
          :class="{'active':this.currentPage=='service' || (this.currentSection == 2 && this.currentPage == 'home' )}"
        >Serivce
          <br>服務項目
        </router-link>
        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'products'}"
          :class="{'active':this.currentPage=='products' || (this.currentSection == 3 && this.currentPage == 'home' )}"
        >Products
          <br>產品
        </router-link>
        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'CaseStudies'}"
          :class="{'active':this.currentPage=='CaseStudies' || (this.currentSection == 4 && this.currentPage == 'home' )}"
        >Cases
          <br>精選案例
        </router-link>
        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'blog'}"
          :class="{'active':this.currentPage=='blog' || (this.currentSection == 5 && this.currentPage == 'home' )}"
        >Blog
          <br>部落格
        </router-link>
        <router-link
          @mouseover.native.capture="navHov"
          @mouseleave.native="navHovOut"
          :to="{name:'contact'}"
          :class="{'active':this.currentPage=='contact' || (this.currentSection == 6 && this.currentPage == 'home' )}"
        >Contact
          <br>聯絡我們
        </router-link>
        <hr id="hoverBar" :class="{'hideBar': hideBar && this.currentPage == 'home'}">
      </nav>
    </div>

    <router-view class="routerView"/>

    <transition name="opaFadeIn">
      <loading v-if="isLoading" />
    </transition>

  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  components: {
    loading
  },
  name: "layout",
  data() {
    return {
      isLoading: true,
      currentPage: "",
      currentSection: 0,
      st: 0,
      lastScrollTop: 0,
      blockClick: true,
      menuOpen: false,
      hooked: false,
      hideBar: true
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    $("html").css({ "overflow-x": "hidden" });
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      // if (st > this.lastScrollTop && !this.menuOpen && this.currentPage != "home") {
      //   $(".topBar").css({ transform: "scaleY(0)" });
      // } else {
      //   $(".topBar").css({ transform: "scaleY(1)" });
      // }
      this.lastScrollTop = st <= 0 ? 0 : st;
      if (this.currentPage != "home" && $("header").length > 0) {
        this.hooked =
          st > $("header").offset().top + $("header").height() - 350
            ? true
            : false;
      }
    },
    navHov(e) {
      var left =
        $(e.target).offset().left -
        $(e.target.parentElement).offset().left +
        $(e.target)[0].clientWidth / 2 -
        $("#hoverBar").width() / 2;
      $("#hoverBar").css({ left: left + "px" });
    },
    navHovOut() {
      if ($("nav a.active").length > 0) {
        var left =
          $("nav a.active").offset().left -
          $($("nav a.active")[0].parentElement).offset().left +
          $("nav a.active")[0].clientWidth / 2 -
          $("#hoverBar").width() / 2;
        $("#hoverBar").css({ left: left + "px" });
      }
    },
    toggleNav() {
      if (this.blockClick) {
        this.menuOpen = !this.menuOpen;
        this.blockClick = false;
        if ($(".mobileNav").hasClass("active")) {
          setTimeout(() => {
            $(".mobileNav").css({ display: "none" });
          }, 400);
        } else {
          $(".mobileNav").css({ display: "flex" });
        }
        setTimeout(() => {
          this.blockClick = true;
        }, 410);
      }
    }
  },
  watch: {
    currentPage() {
      var vm = this;
      $("html").css({ "overflow-x": "hidden" });
      $("html, body").animate({ scrollTop: 0 }, 10);
      vm.hooked = vm.currentPage != "home";
      vm.hideBar = true;
      setTimeout(function() {
        vm.navHovOut();
      })
    },
    currentSection() {
      var vm = this;
      setTimeout(function() {
        vm.navHovOut();
      })
    }
  }
};
</script>

<style lang="scss">
// global css
@import "./assets/scss/style.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#app {
  position: relative;
  text-align: center;
}

$navHeight: 60px;

#nav {
  position: fixed;
  width: 100%;
  height: $navHeight;
  transition: 0.2s;
  z-index: 10;
  @media #{$tablet} {
    display: none;
  }
  nav {
    hr {
      background-color: #fff;
    }
    a,
    div {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      height: 100%;
      span {
        transform: translateY(2px);
      }
    }
  }
  &.inPage:hover,
  &.hooked {
    background-color: #fff;
    box-shadow: 0px 1px 15px -3px #000;
    .logo {
      background-image: url("./assets/image/logoBlack.png");
    }
    nav {
      hr {
        background-color: #707070;
      }
      a,
      div {
        color: #707070;
      }
      a:hover {
        & ~ hr {
          background-color: #fff;
        }
        color: #fff;
        background-color: #73cccf;
      }
    }
  }
}

#hoverBar.hideBar {
  opacity: 0;
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 60px;
  background-color: rgba(34, 34, 34, 0.705);
  z-index: 12;
  transition: 0.3s;
  transform-origin: 50% 0;
  @media #{$tablet} {
    display: block;
  }
  &.active {
    background-color: transparent;
  }
}

.switcher {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 22px;
  width: 32px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 1px;
    transition: 0.3s ease-in-out;
  }
  &.active {
    div {
      background-color: #707070;
    }
    .bar1 {
      width: 141.2%;
      transform: rotate(45deg) translate(4.5px, 10px);
    }
    .bar2 {
      width: 0;
    }
    .bar3 {
      width: 141.2%;
      transform: rotate(-45deg) translate(4.5px, -10px);
    }
  }
}

.mobileNav {
  position: fixed;
  background-color: #fff;
  width: 100vw;
  height: 90vh;
  flex-direction: column;
  justify-content: space-evenly;
  font-weight: 300;
  color: #707070;
  z-index: 11;
  overflow: hidden;
  opacity: 0;
  padding: 5vh 0 5vh 0;
  transform: scale(1.1);
  transition: 0.5s;
  @media only screen and (min-width: 769px) {
    display: none !important;
  }
  &.active {
    display: flex;
    opacity: 1 !important;
    transform: scale(1);
  }
  .lang {
    position: absolute;
    top: 26px;
    left: 4vh;
  }
  a {
    $size: 30px;
    color: #707070;
    text-decoration: none;
    font-size: $size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: $size * 0.6;
    }
    @for $i from 1 through 7 {
      &:nth-child(#{$i + 1}) {
        &.active ~ hr {
          top: (14% * $i) + 6;
        }
      }
    }
  }
  hr {
    position: absolute;
    left: 10vw;
    height: 1px;
    width: 80vw;
    background-color: #a5a5a5;
    transition: 0.3s;
  }
}

.logo {
  background-image: url("./assets/image/logoWhite.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  width: 93px;
  height: 117px;
  left: 50px;
  top: 40px;
  z-index: 10;
  transition: 0.2s;
  &.small {
    left: 15px;
    top: 6px;
    height: 50px;
    &:hover {
      background-image: url("./assets/image/logoFull.png") !important;
    }
  }
  &.mobile {
    position: absolute;
    left: 15px;
    top: 12px;
    height: 40px;
  }
}
nav {
  z-index: 10;
  display: flex !important;
  align-items: center;
  height: $navHeight;
  padding: 0px;
  position: fixed;
  white-space: nowrap;
  right: 0;
  top: 0;
  color: #fff;
  .lang {
    margin-right: 20px;
    // cursor: pointer;
    font-size: 12px;
    opacity: 0;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    @include font(smaller);
    padding: 0px 17px;
    color: #fff;
    line-height: 1.2;
    transition: 0.2s;
    &:hover {
      color: #73cccf;
    }
  }
  hr {
    position: absolute;
    height: 2px;
    width: 80px;
    transition: 0.3s;
    bottom: -4px;
  }
}
</style>
