import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueFullPage from "vue-fullpage.js";
import VueYoutube from "vue-youtube";
import Vue2TouchEvents from "vue2-touch-events";
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueFullPage);
Vue.use(VueYoutube);
Vue.use(Vue2TouchEvents),
  {
    disableClick: false //FBAds 輪撥用
  };
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCjbP-pxDnhRhJnkzoJwo-BptSi9IQuixY',
    libraries: 'places',
  }
});
Vue.config.productionTip = false;

Vue.filter("currency", function(n, fixTo) {
  return fixTo == 1
    ? Number(n).toFixed(fixTo) + " %"
    : "NT $ " +
        Number(n)
          .toFixed(fixTo)
          .replace(/./g, function(c, i, a) {
            return i && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          });
});

Vue.filter("pureCurrency", function(n, fixTo) {
  return Number(n)
    .toFixed(fixTo)
    .replace(/./g, function(c, i, a) {
      return i && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
});

Vue.filter("setNum", function(n) {
  if (isNaN(n)) {
    return 0;
  } else if (!isFinite(n)) {
    return "∞";
  } else {
    return n;
  }
});

Vue.filter("indusHolder", function(n) {
  return n == ""
  ? "請選擇您的產業"
  : n;
});

Vue.filter("planHolder", function(n) {
  return n == ""
  ? "請選擇您的答案"
  : n;
});

Vue.filter("decimalLeadingZero", function(n) {
  return Number(n) < 10
  ? "0" + n
  : n;
});

Vue.filter("blogDate", function(n) {
  return n.indexOf("/") > -1
  ? n.replace(/\//g, ", ")
  : n;
});


new Vue({
  router,
  render: h => h(App),
  mounted () {  // 一般不會用, render-event 是為了prerender
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
