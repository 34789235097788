<template>
  <div class="innerContent" style="position: relative;top: 20%;">
    <div class="subTitle">{{subTitle.split("").join(" ").toString()}}</div>
    <div class="title my">{{title}}</div>
    <hr/>
    <div class="ques my">{{ques}}</div>
    <div class="ans">{{ans}}</div>
    <router-link :to="{name: linkTo}" class="readMore"> 閱讀更多 </router-link>
  </div>
</template>

<script>
export default {
  name: "innerContent",
  props: {
    title: String,
    subTitle: String,
    ques: String,
    ans: String,
    linkTo: String
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

@mixin fontRaito($size) {
  font-size: $size;
  @media #{$phone} {
    font-size: $size * 0.9;
  }
  @media #{$slim} {
    font-size: $size * 0.85;
  }
}

.my {
margin: 1% 0;
}

.innerContent {
  color: #fff;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    width: 50px;
    height: 3px;
    background-color: #fff;
  }
  .title {
    @include fontRaito(40px);
  }
  .subTitle {
    @include fontRaito(50px);
  }
  .ques {
    @include fontRaito(22px);
  }
  .ans {
    @include fontRaito(15px);
  }
  .readMore {
    margin-top: 40px;
    border: #fff solid 1px;
    // padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include fontRaito(20px);
    text-decoration: none;
    color: #fff;
    width: 224px;
    height: 66px;
    transition: 0.5s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
