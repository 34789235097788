<template>
<full-page ref="fullpage" :options="options" id="fullpage">
  <div class="homeBody">
    <div class="home outerContainer section">
      <div class="centerLogo"  style="position: relative;top: 20%;">
        <img src="@/assets/image/home/centerLogo.png">
        <hr />
        <span style="font-weight:300">Digital Marketing From Tsai Yi tech.</span>
        <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll0"><span class="arrow"></span></div>
      </div>
    </div>
    <div class="about outerContainer section">
      <home-inner-content linkTo="about" title="About Us"
      subTitle="關於我們" ques="WHAT WE DO" ans="FB 廣告投放、行銷、粉絲團經營、創意影片平台等.." />
      <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll"><span class="arrow"></span></div>
    </div>
    <div class="service outerContainer section">
      <home-inner-content linkTo="service" title="Service"
      subTitle="服務項目" ques="WHAT WE PROVIDE" ans="用心經營是我們的職責" /> 
      <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll"><span class="arrow"></span></div>
    </div>
    <div class="products outerContainer section"> <home-inner-content linkTo="products" title="Prodocts"
      subTitle="開發產品" ques="WHAT WE CREATE" ans="創新、獨特、牢靠是我們研發團隊的精神" /> 
      <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll"><span class="arrow"></span></div>
    </div>
    <div class="caseStudies outerContainer section">
      <home-inner-content linkTo="CaseStudies" title="Case Studies"
      subTitle="精選案例" ques="TRUST US" ans="我們的專業值得您的信賴" /> 
      <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll"><span class="arrow"></span></div>
    </div>
    <div class="blog outerContainer section">
      <home-inner-content linkTo="blog" title="Blog"
      subTitle="部落格" ques="WHAT'S NEWS" ans="回顧之前、肯定現在、挑戰未來" />  
      <div @click="$refs.fullpage.api.moveSectionDown()" class="scroll"><span class="arrow"></span></div>
    </div>
    <div class="contact outerContainer section">
      <home-inner-content linkTo="contact" title="Contact"
      subTitle="聯絡我們" ques="TELL US YOUR STORY" ans="告訴我們您的故事，讓我們為您打造全新品牌" /> 
    </div>
  </div>
</full-page>
</template>

<script>
// @ is an alias to /src
import homeInnerContent from "@/components/homeInnerContent.vue";
export default {
  name: "home",
  components: {
    homeInnerContent
  },
  data() {
    return {
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        scrollBar: false,
        scrollingSpeed: 1500,
        navigation: true,
        navigationPosition: "right",
        slidesNavPosition: "right",
        slidesNavigation: true,
        showActiveTooltip: true,
        navigationTooltips: ["HOME", "ABOUT", "SERVICE", "PRODUCTS", "CASE", "BLOG", "CONTACT"],
        onLeave: this.onLeave,
      }
    };
  },
  mounted() {
    var vm = this;
    vm.$parent.currentPage = "home";
    setTimeout(function() {
      vm.$parent.navHovOut();
      vm.$parent.isLoading = false;
    }, 2000);
    setTimeout(() => {
      var parent = $(".fp-right.fp-show-active")[0].parentElement;
      var sideNav = $(".fp-right.fp-show-active")[0];
      $(".fp-right.fp-show-active").remove();
      $(parent).append(sideNav);
    })
    
  },
  methods: {
    onLeave(from, to, direction) {
      var vm = this;
      this.$parent.currentSection = to.index;
      if (to.index > 0) {
        this.$parent.hideBar = false;
      } else if (to.index == 0) {
        this.$parent.hideBar = true;
      }
    }
  }
};
</script>


<style lang="scss">
@import "@/assets/scss/style.scss";

hr {
  border: none;
  background-color: #fff;
  height: 1px;
}

.home {
  background-image: url("../assets/image/home/home.jpg");
  display: flex;
  .centerLogo {
    color: #fff;
    font-weight: 500;
    img {
      height: 40vh;
      max-height: 320px;
      opacity: 1;
      animation: fadeIn 3s;
      @media #{$phone} {
        height: 30vh;
      }
    }
    hr {
      margin: 20px auto;
      max-width: 500px;
      width: 80vw;
      background-color: #fff;
    }
  }
}

.outerContainer {
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.about {
    background-image: url("../assets/image/home/about.jpg");
  }
  &.service {
    background-image: url("../assets/image/home/service.jpg");
  }
  &.products {
    background-image: url("../assets/image/home/products.jpg");
  }
  &.caseStudies {
    background-image: url("../assets/image/home/caseStudies.jpg");
  }
  &.blog {
    background-image: url("../assets/image/home/blog.jpg");
  }
  &.contact {
    background-image: url("../assets/image/home/contact.jpg");
  }
}

#fp-nav {
  span {
    background: #fff !important;
  }
  @media #{$phone} {
    display: none;
  }
}

.scroll0 {
  cursor: pointer;
  transform: translateY(100px);
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  .arrow {
    width: 30px;
    height: 30px;
    border: solid;
    border-color: #fff;
    border-width: 0 3px 3px 0;
    animation: 1.5s jumping infinite;
  }
}

.scroll {
  cursor: pointer;
  transform: translateY(300px);
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  .arrow {
    width: 30px;
    height: 30px;
    border: solid;
    border-color: #fff;
    border-width: 0 3px 3px 0;
    animation: 1.5s jumping infinite;
  }
}

@keyframes jumping {
  0%,
  100% {
    transform: rotate(45deg) translate(0px, 0px);
  }
  50% {
    transform: rotate(45deg) translate(10px, 10px);
  }
}
</style>
