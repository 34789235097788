<template>
  <div id="loading" :class="{'fullpage': fullpage}">
    <div
      data-v-754c4e0e
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      class="v-progress-circular v-progress-circular--indeterminate red"
      style="height: 120px; width: 120px;"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="22.22222222222222 22.22222222222222 44.44444444444444 44.44444444444444"
        style="transform: rotate(0deg);"
      >
        <circle
          fill="transparent"
          cx="44.44444444444444"
          cy="44.44444444444444"
          r="20"
          stroke-width="4.444444444444445"
          stroke-dasharray="125.664"
          stroke-dashoffset="125.66370614359172px"
          class="v-progress-circular__overlay"
        ></circle>
      </svg>
      <div class="v-progress-circular__info"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullpage: {
      default: true,
      type: Boolean
    }
  },
  mounted() {
    if (this.fullpage) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    }
    setInterval(function() {
      if ($(".v-progress-circular").hasClass("red")) {
        $(".v-progress-circular").removeClass("red").addClass("purple");
      } else if ($(".v-progress-circular").hasClass("purple")) {
        $(".v-progress-circular").removeClass("purple").addClass("blue");
      } else if ($(".v-progress-circular").hasClass("blue")) {
        $(".v-progress-circular").removeClass("blue").addClass("red");
      }
    }, 1400);
  },
  destroyed() {
    clearInterval();
    if (this.fullpage) {
      document.getElementsByTagName("html")[0].style.overflow = null;
      document.getElementsByTagName("html")[0].style.overflowX = "hidden";
    }
  }
};
</script>

<style lang="scss" scoped>
#loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  &.fullpage {
    width: 100vw;
    height: 100vh;
    background-color: #000000cc;
  }
}

@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}


.red {
  color: #f12379 !important;
  caret-color: #f12379 !important;
}
.purple {
  color: #9d4dce !important;
  caret-color: #9d4dce !important;
}
.blue {
  color: #73cccf !important;
  caret-color: #73cccf !important;
}

.v-progress-circular {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
}

.v-progress-circular--indeterminate {
  svg {
    animation: progress-circular-rotate 1.4s linear infinite;
    transform-origin: center center;
    transition: all 0.2s ease-in-out;
  }
  .v-progress-circular__overlay {
    animation: progress-circular-dash 1.4s ease-in-out infinite;
    stroke-linecap: round;
    stroke-dasharray: 80, 200;
    stroke-dashoffset: 0px;
  }
}

.v-progress-circular__underlay {
  stroke: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.v-progress-circular__overlay {
  stroke: currentColor;
  z-index: 2;
  transition: all 0.6s ease-in-out;
}

.v-progress-circular__info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
