import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  base: "/",
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path:"/",
      name: "home",
      component: Home
    },
    {
      path:"/about_us",
      name: "about",
      component: () => import("./views/About.vue")
    },
    {
      path:"/service",
      name: "service",
      component: () => import("./views/Service.vue"),
      children: [
        {
          path:"/video_service",
          name: "filming",
          component: () => import("./views/service/filming.vue")
        },
        {
          path:"/service/mediaAds",
          name: "mediaAds",
          component: () => import("./views/service/mediaAds.vue"),
          children: [
            {
              path:"/service/mediaAds/FBAds",
              name: "FBAds",
              component: () => import("./views/service/mediaAds/FBAds.vue")
            },
            {
              path:"/service/mediaAds/LineAds",
              name: "LineAds",
              component: () => import("./views/service/mediaAds/LineAds.vue")
            },
            {
              path:"/service/mediaAds/GoogleAds",
              name: "GoogleAds",
              component: () => import("./views/service/mediaAds/GoogleAds.vue")
            }
          ]
        },
        {
          path:"/service/promoting",
          name: "promoting",
          component: () => import("./views/service/promoting.vue")
        },
        {
          path:"/service/socialMediaManagement",
          name: "socialMediaManagement",
          component: () => import("./views/service/socialMediaManagement.vue")
        },
        {
          path:"/service/visialDesign",
          name: "visialDesign",
          component: () => import("./views/service/visialDesign.vue")
        },
        {
          path:"/application",
          name: "website",
          component: () => import("./views/service/website.vue")
        }
      ]
    },
    {
      path:"/fans_play",
      name: "products",
      component: () => import("./views/Products.vue"),
      children: [
        {
          path:"/fans_play/game",
          name: "game",
          component: () => import("./views/products/game.vue")
        },
        {
          path:"/fans_play/onePage",
          name: "onePage",
          component: () => import("./views/products/onePage.vue")
        }
      ]
    },
    {
      path:"/edm",
      name: "blog",
      component: () => import("./views/Blog.vue")
    },
    {
      path:"/app_case",
      name: "CaseStudies",
      component: () => import("./views/CaseStudies.vue")
    },
    {
      path:"/contact",
      name: "contact",
      component: () => import("./views/Contact.vue")
    }
  ]
});
